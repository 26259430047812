
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import ChevronSvg from '@svg/Chevron.vue';
/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    ChevronSvg
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    getLink(link) {
      if (link.startsWith('http://') || link.startsWith('https://')) {
          return link;
      }
      return `/${link}`;
    },

    isExternalLink(link) {
      return (link.startsWith('http://') || link.startsWith('https://'));
    }
  },
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class NotificationBlock extends Vue {}
